import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { getChildrenToRender } from "./utils";

class Banner5 extends React.PureComponent {
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: "bottom",
      one: {
        y: "+=30",
        opacity: 0,
        type: "from",
        ease: "easeOutQuad",
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={["easeOutQuad", "easeInQuad"]}
            {...dataSource.childWrapper}
            componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs,
            }}
          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
          <TweenOne animation={animType.one} key="title" {...dataSource.image}>
            <div className="banner-svg">
              <svg
                id="b89bf718-7a91-4c53-bd44-87446f6864fe"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="618.67538"
                height="487.32493"
                viewBox="0 0 618.67538 487.32493"
              >
                <path
                  d="M439.19457,470.558c-4.93763-26.4263,2.83614-56.49071,24.44276-72.48657a123.86324,123.86324,0,0,0,25.82184,81.0254c6.93546,8.88938,15.575,18.05438,15.3066,29.32608-.16685,7.01352-3.95986,13.54729-8.95834,18.46962-4.99885,4.92244-11.15635,8.4713-17.24022,11.965l-.947,1.47318C459.78583,520.21435,444.13219,496.9843,439.19457,470.558Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#f0f0f0"
                />
                <path
                  d="M464.031,398.47542a105.86978,105.86978,0,0,0-6.98907,64.76135,45.59106,45.59106,0,0,0,4.82767,13.44405,26.14858,26.14858,0,0,0,9.87837,9.58161c3.77061,2.17874,7.87983,3.9707,11.05516,7.02808a16.01082,16.01082,0,0,1,4.71318,12.21634c-.152,5.62259-2.31531,10.85523-4.60862,15.8988-2.5463,5.59993-5.27982,11.37615-5.11468,17.672.02.76283-1.16482.74927-1.1848-.01241-.28731-10.95373,7.6319-19.75079,9.39647-30.26133.82338-4.9044.17374-10.08666-3.27429-13.88828-3.01516-3.32434-7.251-5.17585-11.08793-7.34888a27.90258,27.90258,0,0,1-10.008-9.00886,42.1481,42.1481,0,0,1-5.32674-13.20885,102.71242,102.71242,0,0,1-2.32806-32.01772,107.74713,107.74713,0,0,1,9.03456-35.46463c.31048-.69431,1.32523-.08107,1.01677.60871Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M455.1691,456.308a15.88384,15.88384,0,0,1-16.57127-12.18327c-.17318-.74241.97236-1.0464,1.14577-.303a14.70808,14.70808,0,0,0,15.4379,11.30147c.76028-.05715.74352,1.128-.0124,1.1848Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M469.69065,485.40952a30.61478,30.61478,0,0,0,7.6736-20.95c-.0173-.76288,1.16751-.74955,1.1848.0124a31.844,31.844,0,0,1-8.0294,21.7842c-.5077.57079-1.334-.27886-.829-.84655Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M456.335,421.81686a8.991,8.991,0,0,0,7.987-2.99869c.50022-.57694,1.326.27336.829.84655a10.07516,10.07516,0,0,1-8.82846,3.33694.61234.61234,0,0,1-.5862-.5986.59543.59543,0,0,1,.5986-.5862Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M551.99626,419.93c-.3021.36914-.60419.73828-.90372,1.1171a118.40662,118.40662,0,0,0-10.837,15.85813c-.25247.4268-.5019.86314-.74157,1.29631a124.827,124.827,0,0,0-14.56489,43.58858,121.22216,121.22216,0,0,0-1.1114,18.385c.13349,8.49015.95374,17.685-1.6487,25.49357a20.7962,20.7962,0,0,1-.93651,2.39744l-41.76166,13.30563c-.11008-.01739-.21761-.02515-.32816-.0424l-1.64313.6075c-.02711-.31672-.04842-.64578-.07553-.9625-.01649-.18365-.02368-.37027-.04017-.55392-.00805-.12337-.01565-.247-.03-.35783-.00284-.04105-.0052-.08226-.005-.11383-.01432-.11088-.01584-.21538-.02712-.31672-.12421-1.83906-.23913-3.68114-.33449-5.52939-.003-.00954-.003-.00954.00322-.022-.7168-14.07671-.26472-28.33693,2.33742-42.07519.07843-.4133.15335-.836.25085-1.25539a115.67539,115.67539,0,0,1,5.11549-18.17043,102.26,102.26,0,0,1,4.12386-9.4897,85.03636,85.03636,0,0,1,15.4366-21.64774c12.45869-12.68053,28.83416-21.23539,46.38074-21.50476C551.10433,419.93082,551.54435,419.927,551.99626,419.93Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#f0f0f0"
                />
                <path
                  d="M552.07125,420.48992a105.86977,105.86977,0,0,0-44.57119,47.50041,45.59089,45.59089,0,0,0-4.23962,13.64092,26.14852,26.14852,0,0,0,2.11853,13.59783c1.69887,4.00977,3.901,7.91459,4.59554,12.2675a16.01085,16.01085,0,0,1-3.59187,12.59173c-3.50659,4.39778-8.38424,7.27332-13.25191,9.91959-5.40461,2.93818-11.06486,5.9044-14.72356,11.03072-.4433.62113-1.38115-.103-.93852-.72323,6.3655-8.91892,17.985-11.175,25.722-18.50465,3.61022-3.42017,6.2116-7.949,5.74737-13.06037-.40595-4.46963-2.67333-8.49826-4.42856-12.54337a27.90252,27.90252,0,0,1-2.56685-13.21857,42.1476,42.1476,0,0,1,3.69953-13.75359,102.71244,102.71244,0,0,1,17.418-26.966,107.7471,107.7471,0,0,1,28.56574-22.87709c.66593-.36744,1.10694.73315.44536,1.09818Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M510.17631,461.33048a15.88383,15.88383,0,0,1-5.89607-19.7047c.30871-.697,1.40639-.25007,1.09727.4479a14.70809,14.70809,0,0,0,5.522,18.31828c.64145.41211-.08546,1.34828-.72323.93852Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M504.24981,493.30942a30.61481,30.61481,0,0,0,18.74032-12.1074c.44549-.61954,1.38348.10445.93853.72323a31.844,31.844,0,0,1-19.52661,12.55921c-.749.15008-.8972-1.02578-.15224-1.175Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M531.87321,434.4932a8.991,8.991,0,0,0,8.18263,2.41447c.74676-.1595.89413,1.01658.15224,1.175a10.07517,10.07517,0,0,1-9.0581-2.651.61234.61234,0,0,1-.10765-.83088.59542.59542,0,0,1,.83088-.10765Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M617.59461,275.42124a11.998,11.998,0,0,1,.27275,1.86948L666.83157,305.538l11.90145-6.85145,12.68763,16.60992-19.89042,14.17669a9.11865,9.11865,0,0,1-10.94032-.26653l-50.49052-39.83232a11.96492,11.96492,0,1,1,7.49522-13.953Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#ffb6b6"
                />
                <path
                  d="M667.51134,302.41921a5.12374,5.12374,0,0,1,2.55812-3.42018l22.60587-12.31694a14.24493,14.24493,0,0,1,17.22192,22.6952l-18.118,18.40583a5.12928,5.12928,0,0,1-7.7502-.50981L668.4298,306.59093A5.1235,5.1235,0,0,1,667.51134,302.41921Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#3f3d56"
                />
                <path
                  d="M707.89758,230.8132c3.25253-10.3657,10.64889-19.87274,20.87788-23.37691,10.22961-3.50416,25.20832,1.83383,32.89869,9.49818,14.06716,14.01962,17.50009,62.18212,7.83535,77.402-1.92234-.10176-8.55563-.177-10.50126-.24989l-2.75233-9.17342v9.083c-15.18626-.46933-30.99655-.17547-46.81542.12842C708.02264,283.34875,704.645,241.17889,707.89758,230.8132Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#2f2e41"
                />
                <polygon
                  points="412.883 459.573 399.032 459.573 392.443 406.146 412.886 406.147 412.883 459.573"
                  fill="#ffb6b6"
                />
                <polygon
                  points="534.137 450.96 520.734 454.453 500.881 404.416 520.663 399.261 534.137 450.96"
                  fill="#ffb6b6"
                />
                <polygon
                  points="457.805 131.433 460.944 139.593 460.316 147.753 398.892 169.028 401.94 133.316 408.844 125.155 457.805 131.433"
                  fill="#ffb6b6"
                />
                <circle
                  cx="438.38411"
                  cy="36.06123"
                  r="21.93835"
                  fill="#ffb7b7"
                />
                <path
                  d="M713.97213,213.83116a27.55525,27.55525,0,0,1,41.89371,24.04843c-11.95861,3.05648-24.70245,4.33492-38.20715.73613l-3.087-7.55528-1.82,7.56087q-6.19739,4.35942-11.96314-.0157A28.13543,28.13543,0,0,1,713.97213,213.83116Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#2f2e41"
                />
                <path
                  d="M709.74178,692.21133h-9.82872l-1.75438-9.27947-4.49323,9.27947H667.59736a5.85987,5.85987,0,0,1-3.32977-10.682l20.8172-14.37711V657.771l21.89607,1.3069Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#2f2e41"
                />
                <path
                  d="M838.64387,680.86813l-9.51091,2.47915-4.03827-8.53691-2.00733,10.11278-25.2252,6.5753a5.85987,5.85987,0,0,1-5.91649-9.49672l16.51768-19.16308-2.36628-9.07789,21.51772-4.25833Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#2f2e41"
                />
                <path
                  d="M823.09978,623.69868l-40.60126-83.44757L769.753,438.685c8.78784-16.32032-7.53247-49.52881-7.53247-49.52881V375.21321L699.00011,387.14v.00007a303.46685,303.46685,0,0,0-18.4925,110.18756L683.055,632.33753h-.79913a5,5,0,0,0,0,10h25a5,5,0,1,0,0-10h-.6446l17.947-171.75659,20.86791,84.75757,50.4297,78.03937a4.98737,4.98737,0,0,0,.4,9.95965h25a4.99479,4.99479,0,0,0,1.84387-9.63885Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#2f2e41"
                />
                <path
                  d="M767.93633,397.70436h-.00007a3.98673,3.98673,0,0,0-3.6051-2.99482c-.83331-9.52728-6.33935-70.62872-6.54883-72.42126l-.00817-.07581-1.5943-34.05774a5.03657,5.03657,0,0,0-4.70252-4.79034l-8.42078-.55042a.4456.4456,0,0,1-.4306-.44531,7.08138,7.08138,0,0,0-7.08136-7.08142H710.61345a6.63,6.63,0,0,0-6.57562,5.80383.44014.44014,0,0,1-.448.39612l-.003-.00006a11.84463,11.84463,0,0,0-11.91547,9.82434c-3.40838,19.831-10.23907,64.83985-6.86188,89.86218,1.17566,8.7107.6413,21.81159.19672,29.0998a5.36474,5.36474,0,0,0,1.33563,3.88958,3.99245,3.99245,0,0,0-2.76635,4.80768h.00006a4,4,0,0,0,4.85638,2.90088l76.60346-19.31085A4,4,0,0,0,767.93633,397.70436Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#3f3d56"
                />
                <path
                  d="M641.33066,540.88731a9.46427,9.46427,0,0,0-7.94542-9.28146L497.57072,509.30349a9.46324,9.46324,0,0,0-3.9134.1739l-31.829,8.23-18.26249,4.72769-33.70263,8.71225a9.494,9.494,0,0,0-7.10734,9.18659V682.6482a9.53548,9.53548,0,0,0,7.51055,9.27354L548.603,690.35529a9.21708,9.21708,0,0,0,1.97646.20546,9.469,9.469,0,0,0,2.854-.43476l82.18915,2.04514a9.44808,9.44808,0,0,0,6.633-9.12338Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M641.33066,540.88731a9.46427,9.46427,0,0,0-7.94542-9.28146L497.57072,509.30349a9.46324,9.46324,0,0,0-3.9134.1739l-31.829,8.23-18.26249,4.72769-33.70263,8.71225a9.494,9.494,0,0,0-7.10734,9.18659V682.6482a9.53548,9.53548,0,0,0,7.51055,9.27354L548.603,690.35529a9.21708,9.21708,0,0,0,1.97646.20546,9.469,9.469,0,0,0,2.854-.43476l82.18915,2.04514a9.44808,9.44808,0,0,0,6.633-9.12338ZM634.91111,689.91l-82.1892,1.955a7.2139,7.2139,0,0,1-3.62089.1739l-138.3444-2.43345a7.15287,7.15287,0,0,1-5.629-6.95718V540.33393a7.12342,7.12342,0,0,1,4.66447-6.68049,5.91067,5.91067,0,0,1,.66406-.20556l35.6554-9.21815,21.08488-5.45506,27.05378-6.99665a6.78179,6.78179,0,0,1,1.77882-.2293,7.03578,7.03578,0,0,1,1.15427.09487L632.99786,533.946a7.13606,7.13606,0,0,1,5.17046,3.73946,7.11625,7.11625,0,0,1,.79058,3.21763l.925,142.16045A7.0903,7.0903,0,0,1,634.91111,689.91Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#ccc"
                />
                <path
                  d="M638.16832,537.6855a1.03008,1.03008,0,0,1-.30043.13433l-39.94826,10.79942-12.43589,3.36L550.113,561.53737a1.37238,1.37238,0,0,1-.30834.03957,1.93522,1.93522,0,0,1-.21347-.01583L406.96974,535.55877l-.47438-.087a1.18938,1.18938,0,0,1,.42694-2.3401l2.86983.52172,139.96509,25.52009,30.48494-8.23783,17.05292-4.60917,39.33159-10.63332.62454-.166a1.19153,1.19153,0,0,1,1.38353.64824.92646.92646,0,0,1,.07117.18973A1.18891,1.18891,0,0,1,638.16832,537.6855Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#ccc"
                />
                <path
                  d="M550.99053,690.83753c-.65007,0-1.1797-.52345-1.18588,2.82494l-1.18587-129.2606c-.00618-4.6547.52036-5.19051,1.17507-5.19668h.0108c.65007,0,1.1797.52345,1.18588,5.17506l1.18588,129.2606c.00617-3.3453-.52037-2.80949-1.17507-2.80332Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#ccc"
                />
                <path
                  d="M598.25591,692.33753l-12.77217.13525L580.24216,550.9357,446.499,524.32459a3.07292,3.07292,0,0,1-.38738-.09486,4.715,4.715,0,0,1-2.54571-1.79464l18.26249-4.72769,5.3681,1.06727,126.90467,25.24342a4.71752,4.71752,0,0,1,3.19394,2.30844C597.69037,547.01434,598.248,691.51539,598.25591,692.33753Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#ccc"
                />
                <path
                  d="M700.55859,281.827a7.13278,7.13278,0,0,0-5.98809-6.995L592.21342,258.02377a7.13207,7.13207,0,0,0-2.94935.13107l-23.988,6.20258-13.76358,3.563-25.40011,6.566a7.15519,7.15519,0,0,0-5.35647,6.92351v83.89238a7.18645,7.18645,0,0,0,5.66035,6.989L630.674,394.47409a6.94672,6.94672,0,0,0,1.48957.15485,7.1363,7.1363,0,0,0,2.151-.32766l61.94214-19.561a7.1206,7.1206,0,0,0,4.999-6.87587Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M591.05446,264.18446a.86575.86575,0,0,0-.2214.02851l-63.15212,16.33171a.89268.89268,0,0,0-.66885.86406v83.89114a.89707.89707,0,0,0,.70667.87338l104.25777,22.18233a.90066.90066,0,0,0,.455-.02211l61.9433-19.56046a.88761.88761,0,0,0,.62317-.85883l-.69416-86.03705a.89125.89125,0,0,0-.74827-.87337L591.19876,264.19609A.92856.92856,0,0,0,591.05446,264.18446Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#611229"
                />
                <path
                  d="M700.55859,281.827a7.13278,7.13278,0,0,0-5.98809-6.995L592.21342,258.02377a7.13207,7.13207,0,0,0-2.94935.13107l-23.988,6.20258-13.76358,3.563-25.40011,6.566a7.15519,7.15519,0,0,0-5.35647,6.92351v83.89238a7.18645,7.18645,0,0,0,5.66035,6.989L630.674,394.47409a6.94672,6.94672,0,0,0,1.48957.15485,7.1363,7.1363,0,0,0,2.151-.32766l61.94214-19.561a7.1206,7.1206,0,0,0,4.999-6.87587Zm-4.83811,91.20915-61.94218,19.561a5.43668,5.43668,0,0,1-2.72889.13106L526.78567,370.54566a5.39079,5.39079,0,0,1-4.24228-5.2433V281.41a5.36856,5.36856,0,0,1,3.51539-5.03478,4.454,4.454,0,0,1,.50047-.15492l26.87182-6.94729,15.89069-4.11122,20.38918-5.27306a5.11131,5.11131,0,0,1,1.34061-.17281,5.30328,5.30328,0,0,1,.86992.0715l102.35708,16.80832a5.37814,5.37814,0,0,1,3.89673,2.81825,5.36322,5.36322,0,0,1,.59583,2.425l.69711,86.03741A5.34366,5.34366,0,0,1,695.72048,373.03618Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#3f3d56"
                />
                <path
                  d="M698.17528,279.414a.7765.7765,0,0,1-.22642.10125l-30.10714,8.139-9.37235,2.53227L631.81208,297.39a1.03438,1.03438,0,0,1-.23238.02982,1.45818,1.45818,0,0,1-.16088-.01193L523.93167,277.81116l-.35752-.06553a.89638.89638,0,0,1,.32177-1.76363l2.16286.3932L631.544,295.60851,654.519,289.4l12.852-3.47372,29.64238-8.01384.47069-.1251a.898.898,0,0,1,1.0427.48854.6979.6979,0,0,1,.05364.143A.896.896,0,0,1,698.17528,279.414Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#3f3d56"
                />
                <path
                  d="M632.47344,394.83753a.89376.89376,0,0,1-.89374-.88559l-.89374-97.41769a.894.894,0,0,1,.8856-.90189h.00814a.89375.89375,0,0,1,.89374.8856l.89374,97.41769a.894.894,0,0,1-.88559.90188Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#3f3d56"
                />
                <path
                  d="M667.84172,382.98653l-9.37235,2.53226L654.519,289.4,553.723,269.34448a2.31582,2.31582,0,0,1-.292-.07149,3.55344,3.55344,0,0,1-1.91858-1.35254l13.76358-3.563,4.04569.80435,95.64214,19.02478a3.55544,3.55544,0,0,1,2.40713,1.73977C667.66894,286.44469,667.83575,382.36692,667.84172,382.98653Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#3f3d56"
                />
                <path
                  d="M663.64287,328.18357a11.9974,11.9974,0,0,1,1.33458,1.33724l56.14627-6.55726,5.46049-12.60039,20.09331,5.75539-7.49893,23.24594a9.11865,9.11865,0,0,1-8.93738,6.31544l-64.28505-1.82747a11.96491,11.96491,0,1,1-2.31329-15.66889Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#ffb6b6"
                />
                <path
                  d="M719.80784,320.05541a5.12374,5.12374,0,0,1,.01139-4.271l10.78767-23.37432a14.24493,14.24493,0,0,1,27.36329,7.932l-3.55278,25.58154a5.12927,5.12927,0,0,1-6.52288,4.21634l-24.86-7.28539A5.12351,5.12351,0,0,1,719.80784,320.05541Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#3f3d56"
                />
                <path
                  d="M908.147,693.37861H291.853a1.19069,1.19069,0,0,1,0-2.38137H908.147a1.19068,1.19068,0,0,1,0,2.38137Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#cacaca"
                />
                <circle
                  cx="120.11268"
                  cy="324.71266"
                  r="35.81102"
                  fill="#611229"
                />
                <path
                  d="M405.88962,549.68816a3.98231,3.98231,0,0,1-3.18646-1.59372l-9.7698-13.0266a3.98339,3.98339,0,1,1,6.37358-4.77986l6.39173,8.52166L422.115,514.18545a3.98356,3.98356,0,1,1,6.629,4.41936L409.20446,547.9142a3.98517,3.98517,0,0,1-3.204,1.77267C405.96354,549.68752,405.92658,549.68816,405.88962,549.68816Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M563.25688,340.838a1.512,1.512,0,0,1-.33105-.03711l-31-7a1.50014,1.50014,0,0,1,.66015-2.92675l31,7a1.50038,1.50038,0,0,1-.3291,2.96386Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#fff"
                />
                <path
                  d="M443.95068,622.57388a1.5126,1.5126,0,0,1-.33167.03114l-31.776-.5351a1.50015,1.50015,0,0,1,.04982-2.99988l31.776.53511a1.50039,1.50039,0,0,1,.28186,2.96873Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#e6e6e6"
                />
                <path
                  d="M454.95068,639.57388a1.5126,1.5126,0,0,1-.33167.03114l-31.776-.5351a1.50015,1.50015,0,0,1,.04982-2.99988l31.776.53511a1.50039,1.50039,0,0,1,.28186,2.96873Z"
                  transform="translate(-290.66231 -206.33753)"
                  fill="#e6e6e6"
                />
              </svg>
            </div>
          </TweenOne>
        </div>
      </div>
    );
  }
}
export default Banner5;
